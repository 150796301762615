import React from "react";
import { Link } from "gatsby";

import { TwitterIcon, LinkIcon, WhatsappIcon, LinkedInIcon } from "./Socials";

const Sharebox = ({ presentation, presenters, body, path }) => {
  const { title } = presentation;

  const names = presenters.map(({ frontmatter }) => frontmatter.name);
  const link = path;

  const message =
    names.length === 1
      ? `Listen to ${names[0]} talk about ${title} at HasuraCon'21. Register here: http://hasura.io/events/hasura-con-2021/register/ #HasuraCon`
      : `Listen to ${names[0]} & ${names[1]} talk about ${title} at HasuraCon'21. Register here: http://hasura.io/events/hasura-con-2021/register/ #HasuraCon`;

  return (
    <div className={`hasConSharebox hasCon21Card`}>
      <div className="m-share-btn-div">
        <Link className="inlineBoxCTA" to="/events/hasura-con-2021/recordings/">
          View Recordings &rarr;
        </Link>
      </div>
      <div className="m-share-box">
        <p>Share this talk:</p>
        <div>
          <a href={`https://twitter.com/share?text=${encodeURIComponent(message)}`}>
            <TwitterIcon />
          </a>
          {/* eslint-disable-next-line */}
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigator.clipboard.writeText(link);
            }}
          >
            <LinkIcon />
          </div>
          <a
            href={`https://wa.me/?text=${encodeURIComponent(message)}`}
            data-action="share/whatsapp/share"
          >
            <WhatsappIcon />
          </a>
          {false && (
            <a
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                window.location
              )}&title=${encodeURIComponent(title)}`}
            >
              <LinkedInIcon />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sharebox;
