import React from "react";
import { Link } from "gatsby";
import PresentationDetail from "../../../../components/hasuracon2021/homepage/PresentationDetail";
// import PresentationPattern from "../../../../components/hasuracon2021/images/talk-detail.png";
import Sharebox from "../../../../components/hasuracon2021/homepage/Sharebox";
import Seo from "../../../../components/seo";
import Layout from "../../../../components/hasuracon2021/Layout";
const canonicalUrl = "https://hasura.io/events/hasura-con-2021/talks/";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021.png",
};

const Arrow = () => (
  <svg width={65} height={68} viewBox="0 0 65 68" fill="none">
    <path
      d="M14.53 35.88L63 53.482v11.215L4.315 34 63 3.303V14.71L14.537 32.118 9.325 33.99l5.205 1.89z"
      strokeWidth={4}
    />
    <defs>
      <linearGradient
        id="linearStyle"
        x1={-6.50002}
        y1={204.001}
        x2={74.7581}
        y2={203.981}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1EB4D4" />
        <stop offset={0.46875} stopColor="#FFC267" />
        <stop offset={1} stopColor="#F47E7E" />
      </linearGradient>
    </defs>
  </svg>
);

const HasuraCon = ({ location, pageContext }) => {
  const { presentation, presenters, body, previous, next, slug } = pageContext;

  return (
    <Layout location={location}>
      <Seo
        title={`HasuraCon’21: ${presentation.title}`}
        description={presentation.meta_description}
        meta={ogImage}
        canonicalLink={canonicalUrl + slug + "/"}
      />
      <p className="hasCon21Breadcrumb">
        <Link to="/events/hasura-con-2021">Conference</Link> /{" "}
        <Link to="/events/hasura-con-2021/talks">Talks</Link> / {presentation.title}
      </p>
      <PresentationDetail
        presentation={presentation}
        presenters={presenters}
        body={body}
        slug={slug}
      />
      <Sharebox
        presentation={presentation}
        presenters={presenters}
        path={canonicalUrl + slug + "/"}
      />
      <nav className="hasCon21Pagination">
        <Link to={`/events/hasura-con-2021/talks/${previous.presentation_slug}/`}>
          <div className="linkBox">
            <div className="navArrowBox">
              <Arrow />
            </div>
            <div className="navTitleBox">
              <h2>{previous.title}</h2>
            </div>
          </div>
        </Link>
        <Link to={`/events/hasura-con-2021/talks/${next.presentation_slug}/`}>
          <div className="linkBox">
            <div className="navTitleBox">
              <h2>{next.title}</h2>
            </div>
            <div className="navArrowBox right">
              <Arrow />
            </div>
          </div>
        </Link>
      </nav>
    </Layout>
  );
};

export default HasuraCon;
